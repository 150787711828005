import { gql, TypedDocumentNode } from '@apollo/client';
import { take } from 'ramda';
import { compact, Vertical, VERTICALS } from '@moda/portal-stanchions';
import { useCountry } from '../../components/CountrySelector';
import { useFavorites } from '../../components/Favorite';
import { VARIANT_CELL_FRAGMENT } from '../../components/Grid/GridCell/VariantCell/VariantCell';
import { TRUNKSHOW_CELL_FRAGMENT } from '../../components/Grid/GridCell/TrunkshowCell/TrunkshowCell';
import {
  VariantsQuery,
  VariantCellFragment,
  VariantsQueryVariables,
  TrunkshowsQuery,
  TrunkshowCellFragment,
  TrunkshowsQueryVariables,
  VariantsFromDesignerQuery,
  VariantsFromDesignerQueryVariables,
  RecommendationsVariantFragment
} from '../../generated/types';
import { getPriceRange } from '../../lib/getPriceRange';
import { getActiveVariantBy } from '../../lib/getActiveVariantBy';
import { usePLPScoring } from '../usePLPScoring';
import { usePreferences } from '../usePreferences';
import { useSkippableQuery } from '../useSkippableQuery';
import { useUser } from '../useUser';
import { useCookies } from '../useCookies';
import { logger } from '../../lib/logger';
import {
  RECOMMENDATIONS_QUERY,
  DESIGNER_AFFINITIES_QUERY,
  RECENTLY_VIEWED_QUERY
} from './recommendations.rest';

export const RECOMMENDATIONS_VARIANT_DATA_FRAGMENT = gql`
  fragment RecommendationsVariantDataFragment on VariantData {
    prices(country_code: $countryCode) {
      currentPrice: current_price {
        price
      }
    }
  }
`;

export const RECOMMENDATIONS_VARIANT_FRAGMENT = gql`
  fragment RecommendationsVariantFragment on Variant {
    id
    gender
    category
    designerSlug: designer_slug
    masterVariant: master_variants_data {
      ...RecommendationsVariantDataFragment
    }
    otherVariants: other_variants_data {
      ...RecommendationsVariantDataFragment
    }
  }
  ${RECOMMENDATIONS_VARIANT_DATA_FRAGMENT}
`;

export const VARIANTS_QUERY: TypedDocumentNode<VariantsQuery, VariantsQueryVariables> = gql`
  query VariantsQuery($ids: [String!], $countryCode: String) {
    variants(ids: $ids) {
      ...VariantCellFragment
    }
  }
  ${VARIANT_CELL_FRAGMENT}
`;

export const TRUNKSHOWS_QUERY: TypedDocumentNode<TrunkshowsQuery, TrunkshowsQueryVariables> = gql`
  query TrunkshowsQuery(
    $designerIds: [String]
    $vertical: Gender
    $excludedCategories: [String]
    $singleDesignerOnly: Boolean
    $limit: Int
  ) {
    trunkshows(
      input: {
        designer_ids: $designerIds
        gender: $vertical
        excluded_categories: $excludedCategories
        single_designer_only: $singleDesignerOnly
      }
    ) {
      trunkshows(first: $limit) {
        edges {
          node {
            ...TrunkshowCellFragment
            categories
            limited_designer_data {
              id
            }
          }
        }
      }
    }
  }
  ${TRUNKSHOW_CELL_FRAGMENT}
`;

export const VARIANTS_FROM_DESIGNER_QUERY: TypedDocumentNode<
  VariantsFromDesignerQuery,
  VariantsFromDesignerQueryVariables
> = gql`
  query VariantsFromDesignerQuery(
    $designer: String
    $categoryPath: String
    $limit: Int
    $countryCode: String
    $scoring: String
  ) {
    productListing: product_listing(
      section: "shop"
      input: { designers: [$designer], category_path: $categoryPath, scoring: $scoring }
    ) {
      variants(first: $limit) {
        edges {
          node {
            ...VariantCellFragment
          }
        }
      }
    }
  }
  ${VARIANT_CELL_FRAGMENT}
`;

const DEFAULT_LIMIT = 40;
const PERCENTAGE_OF_CURRENT_PRICE = 0.3;

export const useVariantsQuery = ({
  variantIds,
  limit = DEFAULT_LIMIT,
  ssr = false
}: {
  variantIds?: string[] | null;
  limit?: number;
  ssr?: boolean;
}) => {
  const {
    country: { alpha3Code }
  } = useCountry();

  return useSkippableQuery(VARIANTS_QUERY, {
    variables: {
      ids: take(limit, compact(variantIds)),
      countryCode: alpha3Code
    },
    skip: !variantIds || variantIds.length === 0,
    ssr,
    onError: error => {
      logger.error('HANDLE_REQUEST', error?.message);
    }
  });
};

export const useTrunkshowsQuery = ({
  designerIds,
  vertical,
  excludedCategories,
  singleDesignerOnly,
  limit
}: TrunkshowsQueryVariables) => {
  const { data, loading, error } = useSkippableQuery(TRUNKSHOWS_QUERY, {
    variables: {
      designerIds,
      vertical,
      excludedCategories,
      singleDesignerOnly,
      limit
    },
    skip: (designerIds || []).length === 0,
    ssr: false,
    onError: error => {
      logger.error('HANDLE_REQUEST', error?.message);
    }
  });

  return {
    trunkshows:
      (data?.trunkshows?.trunkshows?.edges
        ?.map(edge => edge?.node)
        .filter(Boolean) as TrunkshowCellFragment[]) || [],
    loading,
    error
  };
};

export const useRecommendationsQuery = ({
  type,
  id,
  filter,
  ssr = false
}: {
  type: string;
  id?: string | null;
  filter?: string | null;
  ssr?: boolean;
}) =>
  useSkippableQuery(RECOMMENDATIONS_QUERY, {
    variables: { type, id: id || '', limit: DEFAULT_LIMIT, filter: filter || '' },
    skip: !id,
    ssr,
    onError: error => {
      logger.error('HANDLE_REQUEST', error?.message);
    }
  });

export const useVariantsRecommendationsQuery = ({
  type,
  id,
  limit,
  filter,
  ssr
}: {
  type: string;
  id?: string | null;
  limit?: number;
  filter?: string | null;
  ssr?: boolean;
}) => {
  const recommendationsQuery = useRecommendationsQuery({ type, id, filter, ssr });

  const variantsQuery = useVariantsQuery({
    variantIds: (
      recommendationsQuery.data?.recommendations?.data?.attributes?.variantIds || []
    ).map(String),
    limit,
    ssr
  });

  return {
    variants: compact(variantsQuery.data?.variants).filter(variant =>
      variant.masterVariant.inventory.some(size => size?.canSell)
    ),
    loading: recommendationsQuery.loading || variantsQuery.loading,
    error: recommendationsQuery.error || variantsQuery.error
  };
};

export const useFavoritedProducts = () => {
  const { favorites } = useFavorites();
  const variantsQuery = useVariantsQuery({ variantIds: favorites.map(String) });
  return {
    variants: (variantsQuery.data?.variants?.filter(Boolean) as VariantCellFragment[]) || [],
    loading: variantsQuery.loading,
    error: variantsQuery.error
  };
};

export const useSimilarProducts = ({
  variant,
  variantId: variantIdFromArgs,
  ssr
}: {
  variant?: RecommendationsVariantFragment;
  variantId?: string;
  ssr?: boolean;
}) => {
  const variantId = variantIdFromArgs || variant?.id;
  const activeVariant = variant ? getActiveVariantBy(variant, 'id', variant.id) : null;
  const price = activeVariant?.prices.currentPrice?.price;
  const category = variant?.category[0]?.toLowerCase();

  const { minLimit, maxLimit } = getPriceRange(PERCENTAGE_OF_CURRENT_PRICE, price);

  const variantsQuery = useVariantsRecommendationsQuery({
    type: 'visually-similar',
    id: variantId,
    limit: Infinity,
    filter:
      category === 'fine_jewelry'
        ? encodeURIComponent(JSON.stringify({ include: `category,${category}` }))
        : null,
    ssr
  });

  const priceFilteredVariants = take(
    DEFAULT_LIMIT,
    variantsQuery.variants.filter(
      variant =>
        variant.masterVariant.prices.currentPrice &&
        Number(variant.masterVariant.prices.currentPrice.price) >= minLimit &&
        Number(variant.masterVariant.prices.currentPrice.price) <= maxLimit
    )
  );

  return {
    variants: price ? priceFilteredVariants : variantsQuery.variants,
    loading: variantsQuery.loading,
    error: variantsQuery.error
  };
};

export const useTopTrunkshows = () => {
  const { user } = useUser();
  const { vertical } = usePreferences();

  const designerAffinitiesQuery = useSkippableQuery(DESIGNER_AFFINITIES_QUERY, {
    variables: { userId: user.id || '0', limit: DEFAULT_LIMIT },
    skip: !user.id,
    ssr: false,
    onError: error => {
      logger.error('HANDLE_REQUEST', error?.message);
    }
  });

  const trunkshowsQuery = useTrunkshowsQuery({
    designerIds: (designerAffinitiesQuery.data?.designerAffinities?.top || []).map(String),
    vertical,
    excludedCategories: ['Bridal'],
    singleDesignerOnly: true,
    limit: 16
  });

  return {
    trunkshows: trunkshowsQuery.trunkshows,
    loading: designerAffinitiesQuery.loading || trunkshowsQuery.loading,
    error: designerAffinitiesQuery.error || trunkshowsQuery.error
  };
};

export const useRecentlyViewedQuery = (id: string | null | undefined) => {
  return useSkippableQuery(RECENTLY_VIEWED_QUERY, {
    variables: {
      id: id || ''
    },
    skip: !id,
    ssr: false,
    onError: error => {
      logger.error('HANDLE_REQUEST', error?.message);
    }
  });
};

export const useVariantsRecentlyViewedQuery = (id: string | null | undefined) => {
  const rcentlyViewedQuery = useRecentlyViewedQuery(id);

  const variantIds = rcentlyViewedQuery.data?.recentlyViewed?.data.attributes.variantIds || [];

  const variantsQuery = useVariantsQuery({ variantIds });

  return {
    variants: (variantsQuery.data?.variants?.filter(Boolean) as VariantCellFragment[]) || [],
    loading: rcentlyViewedQuery.loading || variantsQuery.loading,
    error: rcentlyViewedQuery.error || variantsQuery.error
  };
};

export const useRecentlyViewed = () => {
  const { user } = useUser();
  const {
    cookies: { modaAnonymousId }
  } = useCookies();

  const result = useVariantsRecentlyViewedQuery(user.id ?? modaAnonymousId);

  if (!modaAnonymousId) {
    return {
      variants: [],
      loading: false,
      error: undefined
    };
  }

  return result;
};

export const useVariantsFromDesigner = (variant?: RecommendationsVariantFragment) => {
  const {
    country: { alpha3Code }
  } = useCountry();
  const scoring = usePLPScoring();

  const { id: variantId, gender, category: categories, designerSlug } = variant || {};

  const vertical = VERTICALS[gender as Vertical]?.toLowerCase();
  const category = categories?.[0]?.replace(/[_\s]/g, '-').toLowerCase();

  const categoryPath = `${vertical}/${category}`;

  const { data, loading, error } = useSkippableQuery(VARIANTS_FROM_DESIGNER_QUERY, {
    variables: {
      designer: designerSlug || '',
      categoryPath,
      limit: DEFAULT_LIMIT,
      countryCode: alpha3Code,
      scoring
    },
    skip: !designerSlug,
    onError: error => {
      logger.error('HANDLE_REQUEST', error?.message);
    }
  });

  return {
    variants: compact(
      data?.productListing?.variants?.edges
        ?.map(edge => edge?.node)
        .filter(variant => variant?.id !== variantId)
    ),
    loading,
    error
  };
};
